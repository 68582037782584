import React, { Fragment } from "react"

import { useAlert } from "react-alert"
import * as Sentry from "@sentry/browser"

import AppBase from "../util/app-base"
import Header from "../components/header"
import Footer from "../components/footer"
import FullScreen from "../components/landing-page/full-screen"
import SiteEnclosingWrapper from "../components/site-enclosing-wrapper"
import { InternalRainbowLink } from "../components/rainbow-link"
import { TextInput } from "../components/forms/input-field"
import { Row } from "../components/layout"

import { Formik } from "formik"
import { RainbowButton } from "../components/forms/button"

import * as styles from "./anmeldung.module.scss"
import { submitSchoolSignUp } from "../util/api"

function Page() {
  const alert = useAlert()

  return (
    <Fragment>
      <SiteEnclosingWrapper>
        <FullScreen style={{ display: "flex", flexDirection: "column" }}>
          <Header
            title="Anmeldung"
            button={
              <InternalRainbowLink
                title="Spenden"
                href="/spenden"
                fillWidth={false}
              />
            }
          />
          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              schoolName: "",
              role: "",
              phoneNumber: "",
              email: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const response = await submitSchoolSignUp(values)

                if (response.error) {
                  switch (response.error.code) {
                    case "input_error": {
                      alert.show("Bitte überprüfe deine Eingaben!", {
                        type: "error",
                      })
                      break
                    }
                    case "sendgrid_error": {
                      Sentry.captureEvent({
                        message: "SendGrid error",
                        level: "error",
                      })
                      alert.show(
                        "Die Bestätigungsmail konnte nicht versandt werden. Dieses Problem wurde an uns übermittelt. Bitte überprüfe deine E-Mail-Adresse oder schreibe uns auf kontakt@flagincluded.at",
                        {
                          type: "error",
                          timeout: 0, // don't automatically disappear
                        }
                      )
                      break
                    }
                    default:
                      Sentry.captureEvent({
                        message: "Unknown error during sign up submition",
                        level: "error",
                        extra: {
                          response: response,
                        },
                      })
                      alert.show(
                        "Ein unbekannter Fehler ist aufgetreten und wurde an uns übermittelt. Probier es am besten nochmal oder schreibe uns direkt an kontakt@flagincluded.at!",
                        {
                          type: "error",
                          timeout: 0, // don't automatically disappear
                        }
                      )
                  }
                  setSubmitting(false)
                  return
                }

                alert.show(
                  "Deine Angaben wurde erfolgreich an uns übermittelt. Du solltest in Kürze eine Anmeldebestätigung per E-Mail erhalten. Sollte binnen der nächsten Minuten keine ankommen, schreib uns bitte auf kontakt@flagincluded.at!",
                  {
                    type: "success",
                    timeout: 0, // don't automatically disappear
                  }
                )
                setSubmitting(false)
              } catch (error) {
                Sentry.captureException(error)
                alert.show(
                  "Ein unbekannter Fehler ist aufgetreten und wurde an uns übermittelt. Probier es am besten nochmal oder schreibe uns direkt an kontakt@flagincluded.at!",
                  {
                    type: "error",
                    timeout: 0, // don't automatically disappear
                  }
                )
                setSubmitting(false)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2 className={styles.heading} style={{ marginBottom: "10px" }}>
                  Anmeldung
                </h2>
                <Row>
                  <TextInput
                    placeholder="Schule"
                    name="schoolName"
                    value={values.schoolName}
                    nativeOnChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <Row>
                  <TextInput
                    placeholder="Position (z.B. Schulleitung, SV, ...)"
                    name="role"
                    value={values.role}
                    nativeOnChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <Row>
                  <TextInput
                    placeholder="Vorname"
                    name="firstname"
                    value={values.firstname}
                    nativeOnChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div style={{ width: "10px", flexShrink: 0 }} />
                  <TextInput
                    placeholder="Nachname"
                    name="lastname"
                    value={values.lastname}
                    nativeOnChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <Row>
                  <TextInput
                    placeholder="E-Mail-Adresse"
                    type="email"
                    name="email"
                    value={values.email}
                    nativeOnChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <Row>
                  <TextInput
                    placeholder="Telefonnummer"
                    type="tel"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    nativeOnChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Row>
                <Row>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      <RainbowButton
                        title="Abschicken"
                        type="submit"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                      />
                    </div>
                  </div>
                </Row>
              </form>
            )}
          </Formik>
        </FullScreen>
      </SiteEnclosingWrapper>
      <Footer />
    </Fragment>
  )
}

export default function AnmeldePage() {
  return (
    <AppBase title="FLAGincluded - Anmeldung">
      <Page />
    </AppBase>
  )
}
