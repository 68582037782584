import React from "react"
import classNames from "classnames"

import * as styles from "./button.module.scss"
import loadingGif from "../../images/ajax-loader.svg"

export const RainbowButton = ({
  title,
  desc,
  fillWidth = true,
  isLoading,
  ...otherProps
}) => (
  <button
    className={classNames(
      styles.box,
      fillWidth && styles.fillWidthOnSmallScreen
    )}
    {...otherProps}
  >
    <div className={styles.innerBox}>
      <div>
        <h3 className={styles.title}>{title}</h3>
        {desc && <p className={styles.desc}>{desc}</p>}
      </div>

      {isLoading && (
        <img alt="Lädt..." src={loadingGif} className={styles.loader} />
      )}
    </div>
  </button>
)
